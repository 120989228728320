import React from 'react';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import { FaAddressCard, FaEnvelope } from 'react-icons/fa';

function encode(data) {
	return Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.ContactForm = React.createRef()
		this.state = {}
	}
	encode = data => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&")
	}
	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value })
	}

	handleSubmit = e => {
		e.preventDefault()
		const form = this.ContactForm.current

		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: this.encode({
				"form-name": form.getAttribute("name"),
				...this.state,
			}),
		})
			.then(response => {
				console.log("====================================")
				console.log(`${JSON.stringify(response, null, 2)}`)
				console.log("====================================")
				navigate(form.getAttribute("action"))
			})
			.catch(error => {
				console.log("====================================")
				console.log(`error in submiting the form data:${error}`)
				console.log("====================================")
			})
	};

	render() {
		return (
			<Layout>
				<section className="section section--gradient">
					<div className="container">
						<h2 className="title is-size-3 has-text-weight-bold is-bold-light">
							Contact
            			</h2>
						<div class="columns is-centered">
							<div class="column is-half">
								<form name="contact"
									method="post"
									action="/contact/thanks/"
									data-netlify="true"
									data-netlify-honeypot="bot-field"
									onSubmit={this.handleSubmit}
									ref={this.ContactForm}>

									{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
									<input type="hidden" name="form-name" value="contact" />

									<div hidden>
										<label>
											Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
										</label>
									</div>
									<div className="field">
										<label className="label has-text-white-ter" htmlFor='name'>
											Your Name
									</label>
										<div className="control has-icons-left">
											<input
												className="input is-large"
												placeholder="Name"
												type='text'
												name='name'
												onChange={this.handleChange}
												id='name'
												required={true} />
											<span className="icon is-medium is-left">
												<FaAddressCard />
											</span>
										</div>
									</div>
									<div className="field">
										<label className="label has-text-white-ter" htmlFor='email'>
											Email Address
									</label>
										<div className="control has-icons-left">
											<input
												className="input is-large"
												placeholder="Email"
												type='email'
												name='email'
												onChange={this.handleChange}
												id='email'
												required={true} />
											<span className="icon is-medium is-left">
												<FaEnvelope />
											</span>
										</div>
									</div>

									<div className="field">
										<label className="label has-text-white-ter" htmlFor='message'>
											Message
										</label>
										<div className="control">
											<textarea
												className="textarea is-large"
												name='message'
												onChange={this.handleChange}
												id='message'
												required={true}
											/>
										</div>
									</div>
									<div className="field">
										<p>Your email will be used for MPSK to contact you only. We will never sell your information to any third parties.</p>
									</div>
									<div className="field">
										<input required={true} className="is-checkradio" id="checkbox" type="checkbox" name="checkbox" />
										<label className="checkout-box-label" for="checkbox">I consent to having this website store my submitted information so they can respond to my inquiry. See our
										<Link className="" to="/privacyPolicy">
												&nbsp;Privacy policy
										</Link>.</label>
									</div>
									<div className="field">
										<input required={true} className="is-checkradio" id="checkbox-terms" type="checkbox" name="checkbox-terms" />
										<label className="checkout-box-label" for="checkbox-terms">I can confirm I have read and accepted the
										<Link className="" to="/termsOfWebsiteUse">
												&nbsp;Terms of Website Use
										</Link> and
										<Link className="" to="/acceptableUsePolicy">
												&nbsp;Acceptable Use Policy
										</Link>.
										</label>
									</div>

									<div className="field">
										<button className="button" type="submit">
											Send
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</Layout >
		);
	}
}





